<template>
    <component
        :class="[
            ...buttonTypesClass,
            hasLoading ? `base-button--has-loading` : null,
            'base-button'
        ]"
        :is="tag"
        :disabled="isDisabled"
        :style="customStyle"
        @click="onClick"
    >
        <span
            key="label"
            v-if="!hasLoading"
            class="base-button__label"
        >
            <slot name="icon"></slot>
            <slot name="label"></slot>
            <slot></slot>
        </span>
        <span
            key="loading"
            v-else
            class="base-button__loading"
        ></span>
    </component>
</template>

<script>
    export default {
        name: 'BaseButton',

        props: {
            tag: {
                type: String,
                default: 'button'
            },

            customizable: {
                type: Object,
                default: () => {}
            },

            isType: {
                type: Array,
                default: () => [
                    'simple'
                ]
            },

            isDisabled: {
                type: Boolean,
                default: false
            },

            hasLoading: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            customStyle() {
                return {
                    '--custom-background' : this.customStyleCheck('background') ? this.customizable.background : '#595959',
                    '--custom-maxWidth' : this.customStyleCheck('maxWidth') ? this.customizable.maxWidth : '100%',
                    '--custom-labelColor' : this.customStyleCheck('labelColor') ? this.customizable.labelColor : '#fff',
                    '--custom-padding' : this.customStyleCheck('padding') ? this.customizable.padding : '0 15px'
                }
            },

            buttonTypesClass() {
                return this.isType.map((type) => {
                    return `base-button--is-${type}`
                })
            },
        },

        methods: {
            customStyleCheck(prop) {
                return this.customizable && this.customizable[prop];
            },

            onClick(e) {
                const { dataset } = e.target
                this.$emit('onClick', {
                    data: dataset
                })
            }
        },
    }
</script>

<style lang="scss" scoped src="./BaseButton.scss"></style>
